<template>
  <div class="wrapper">
    <b-card no-body bg-variant="dark">
      <div class="animated fadeIn">
        <b-row>
          <b-col sm="6" md="4">
            <b-card class="bg-secondary text-center" style="height: 80%;">
              <blockquote class="card-blockquote">
                <p>
                  MERKEZEFENDİ MAHALLESİ, Eski Londra Asfaltı Cd. NO:11, 34015 Zeytinburnu/İstanbul
                </p>
                <footer>
                  <cite title="Veri Yönetimi">Adres</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="4">
            <b-card class="bg-secondary text-center" style="height: 80%;">
              <blockquote class="card-blockquote">
                <p>info@wisdomera.io</p>
                <br>
                <footer>
                  <cite title="Nesnelerin İnterneti">email</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.3997988700316!2d28.911779!3d41.0165084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabbce9c31f443%3A0xdbb0a69fe9a80fe5!2sWisdomEra%20A.%C5%9E!5e0!3m2!1sen!2str!4v1574957724299!5m2!1sen!2str" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Contact',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {},
  props: {},
  data () {
    return {
      WisdomEraAdressMap: ''
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style scoped>
#WisdomEraMap {
  height: 300px;
  width: 100%;
}

</style>

